<style>
.select_box .van-checkbox__label {
  font-size: 25px !important;
}
</style>
<template>
  <div class="contaner_w">
    <new-header :config="headerConfig"></new-header>
    <div class="order_box">
      <div class="search_box">
        <div class="left_box" @click="selectBtn">
          <div>{{ strotText }}</div>
          <img src="../../assets/Vector@2x (1).png" />
        </div>
        <div class="right_box">
          <input v-model="searchInput" placeholder="请输入名称搜索" />
          <div @click="searchBtn">搜索</div>
        </div>
      </div>

      <div class="tabs_content">
        <div class="select_box">
          <van-checkbox
            v-model="checked"
            @change="changeBtn"
            icon-size="20px"
            shape="square"
            label-position="left"
            >全选</van-checkbox
          >
        </div>
        <vue-loadmore
          :on-refresh="onRefresh"
          :on-loadmore="onLoad"
          :finished="finished"
          style="padding-bottom: 100px"
        >
          <van-checkbox-group v-model="result" ref="checkboxGroup" @change="checkboxBtn">
            <div class="list_item_s" v-for="(item, index) in dataList" :key="index">
              <div>
                <div style="display: flex">
                  <div class="bgc_list_icon" v-if="item.module_type == 1">
                    <div>氏客</div>
                  </div>
                  <div class="bgc_list_icon1" v-if="item.module_type == 2">
                    <div>企业</div>
                  </div>
                  <div class="title">{{ item.super.title }}</div>
                </div>

                <div class="text_order_text">{{ item.super.name }}</div>
              </div>
              <div class="price">{{ item.surplus_contribution_amount }}</div>
              <van-checkbox :name="item.super_id" shape="square"></van-checkbox>
            </div>
          </van-checkbox-group>
        </vue-loadmore>
        <van-empty
          v-if="dataList.length == 0"
          class="custom-image"
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
          description="暂无数据"
        />
      </div>
      <!-- footerBtn -->
      <div class="footer_btn" v-if="selectList.length > 0">
        <div class="piont_box">
          <div>已选订单：{{ selectList.length }}</div>
          <div>贡献值：{{ contribution }}</div>
        </div>
        <div class="sureBtn" @click="sureBtn">确认</div>
      </div>
    </div>

    <div v-if="typeShow">
      <custom-data-time @onConfirm="confirmBtnSelect" :config="config"></custom-data-time>
    </div>
  </div>
</template>
<script type>
import newHeader from "@/components/newHeader";
import * as apiCommon from "@/api/common";
import customDataTime from "../../components/custom/customDataTime/index";
// import { Toast } from "vant";
export default {
  data() {
    return {
      headerConfig:{
        show: true,
        title: '助力贡献值选择',
      },
      active: "0",
      checked: false,
      typeShow: false,
      result: [],
      strotText:'全部/门店/实体',
      queryList: {
        page: 1,
        limit: 10,
        type: "",
        search: "",
      },
      dataList: [],
      finished: false,
      selectList: [],
      contribution: 0,
      searchOrder: "",
      config: {
        columns: ["全部", "门店", "实体"],
        type: "text",
        title: "合同类型",
      },
      searchInput:'',
    };
  },
  components: {
    newHeader,
    customDataTime,
  },
  mounted() {
    // this.$refs.tabs.resize();
  },
  beforeDestroy(){
    if(this.selectList.length==0){
      localStorage.removeItem("storeList")
    }else{
      localStorage.setItem("storeList", JSON.stringify(this.selectList));
    }
      
  },
  created() {
    document.title = "";
    this.getList();
  },
  methods: {
    submitBtn() {
      this.show = true;
    },
    // searchBtn() {
    //   this.dataList = [];
    //   this.queryList.page = 1;
    //   this.queryList.search = this.searchOrder;
    //   this.getList();
    // },
    // 确认选中
    sureBtn() {
      localStorage.setItem("storeList", JSON.stringify(this.selectList));
      this.$router.go(-1);
    },

    // 多选订单
    checkboxBtn(e) {
      console.log(e,"e")
      // 选择的订单
      this.selectList = [];
      this.contribution = 0;
      this.dataList.forEach((item) => {
        e.forEach((i) => {
          if (item.super_id == i) {
            this.selectList.push(item);
            this.contribution =
              this.contribution + Number(item.surplus_contribution_amount);
          }
        });
      });
    },
    getList() {
      apiCommon.Contribution_api(this.queryList).then((res) => {
        if (res.code == 0) {
          console.log(res.data);
          this.dataList = this.dataList.concat(res.data);
          if(localStorage.getItem("storeList")){
            this.selectList = JSON.parse(localStorage.getItem("storeList"));
            this.selectList.forEach(e=>{
              this.result.push(e.super_id)
            })
            console.log(this.selectList,this.result)
          }
        }
      });
    },
    changeBtn(e) {
      console.log(e);
      if (e) {
        this.$refs.checkboxGroup.toggleAll(true);
        this.selectList = this.dataList;
      } else {
        this.$refs.checkboxGroup.toggleAll();
        this.selectList = [];
      }

      console.log(e);
    },

    changeBtnType(e) {
      this.queryList.page = 1;
      this.dataList = [];
      if (e) {
        this.queryList.type = e;
      }
      this.getList();
    },
    // 上拉加载------------------
    onRefresh(done) {
      this.dataList = [];
      this.queryList.page == 1;
      this.finished = false;
      this.fetch();
      done();
    },
    onLoad(done) {
      console.log(12);
      // if (this.queryList.page <= 10) {
      this.queryList.page++;
      this.fetch();
      // } else {
      // all data loaded
      this.finished = true;
      // }
      done();
    },

    selectBtn(e){
      console.log(e)
      this.typeShow = true;
    },

    confirmBtnSelect(e){
      this.dataList = [];
      this.queryList.page = 1;
      this.typeShow = false;
      this.strotText = e;
      if(e=='全部'){
        delete this.queryList.module_type
      }else {
        if(e=='实体'){
          this.queryList.module_type = 2
        }else{
          this.queryList.module_type = 1
        }
        
      }
      this.getList()
      
    },

    searchBtn(){
      this.dataList = [];
      this.queryList.page = 1;
      this.queryList.search = this.searchInput;
      this.getList()
    },

    fetch() {
      this.getList();
    },
  },
};
</script>
<style>
.vuejs-refresh-head {
  height: 0 !important;
}
</style>
<style scoped lang="less">
.contaner_w {
  background-color: #f2f2f7;
  min-height: 100vh;
  margin-bottom: 100px;
}

.order_box {
  width: 100%;

  .tab_class {
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 28px;
    color: #fe3b22;
    background-color: #fff;
    border-bottom: 5px solid #fe3b22;
    width: 60px;
    margin-left: 30px;
    padding-top: 30px;
  }
  .tab_header {
    width: 100%;
    height: 80px;
    background-color: #fff;
    margin-top: -30px;
  }
}

van-tab {
  flex: 1;
}

.tabs_content {
  padding: 30px;
  box-sizing: border-box;
}

.select_box {
  display: flex;
  margin-top: 20px;
  z-index: 999;
  justify-content: flex-end;
}

.select_box input {
  width: 400px;
  height: 80px;
  background: #ffffff;
  border-radius: 12px 12px 12px 12px;
  border: none;
  padding: 10px 20px;
  box-sizing: border-box;
  font-size: 25px;
}

.search_btn {
  width: 120px;
  height: 80px;
  background: #078bff;
  border-radius: 12px 12px 12px 12px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  text-align: center;
  line-height: 80px;
  margin: 0 26px 0 10px;
}

/* van-checkbox{
    font-size: 20px;
} */

.list_item_s {
  width: 700px;
  height: 180px;
  background: #ffffff;
  border-radius: 15px 15px 15px 15px;
  margin-top: 30px;
  padding: 45px 34px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #1a1a1a;
  .price {
    font-family: PingFang SC, PingFang SC;
    font-weight: bold;
    font-size: 32px;
    color: #fe3b22;
    margin-top: 23px;
  }
}
.list_item_s .title {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #1a1a1a;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 286px;
}

.bgc_list_icon {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  color: #ff7700;
  width: 66px;
  height: 36px;
  line-height: 36px;
  background: #fff5ed;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #ff7700;
  text-align: center;
  margin-right: 14px;
}

.bgc_list_icon1 {
  width: 66px;
  height: 36px;
  line-height: 36px;
  background: #eef9ee;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #4eb84a;
  color: #4eb84a;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  margin-right: 14px;
}

.bgc_list_icon2 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 32px;
  color: #ff461e;
  width: 100px;
  height: 140px;
  background: #fff7f5;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid#FF461E;
  text-align: center;
  margin-right: 14px;
}

.bgc_list_icon3 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 32px;
  color: #078bff;
  width: 100px;
  height: 140px;
  background: #eff8ff;
  border-radius: 10px 10px 10px 10px;
  border: 1px solid #078bff;
  text-align: center;
  margin-right: 14px;
}

.text_order_text {
  padding-top: 15px;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 345px;
}

.text_order_text div {
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 26px;
  color: #1a1a1a;
  margin-bottom: 22px;
}

.footer_btn {
  width: 750px;
  height: 240px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  position: fixed;
  left: 0;
  bottom: 0;
  padding: 34px 26px;
  box-sizing: border-box;
}

.piont_box {
  display: flex;
  justify-content: space-between;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  font-size: 30px;
  color: #1a1a1a;
}

.sureBtn {
  width: 700px;
  height: 100px;
  background: #fe3b22;
  border-radius: 16px 16px 16px 16px;
  text-align: center;
  line-height: 100px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 500;
  font-size: 28px;
  color: #ffffff;
  margin: 31px auto 0;
}
.tabs_box {
  position: fixed;
  top: 90px;
  left: 0;
  z-index: 999;
}
.search_box {
  display: flex;
  height: 140px;
  background-color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
  .left_box {
    width: 260px;
    height: 80px;
    background: #f2f2f7;
    border-radius: 8px 8px 8px 8px;
    display: flex;
    line-height: 80px;
    padding: 0 20px;
    box-sizing: border-box;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 26px;
    color: rgba(26, 26, 26, 0.5);
    position: relative;
    img {
      position: absolute;
      top: 34px;
      right:20px;
      width: 17px;
      height: 10px;
    }
  }
  .right_box {
    width: 430px;
    height: 80px;
    background: #f2f2f7;
    border-radius: 8px 8px 8px 8px;
    margin-left: 10px;
    display: flex;
    justify-content: space-between;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 26px;
    color: #078bff;
    input {
      border: none;
      width: 330px;
      height: 80px;
      background-color: transparent;
      padding-left:20px;
      box-sizing: border-box;
      color:rgba(26, 26, 26, 0.50)
    }
    div{
        margin: 27px 15px 0 0;
      }
  }
}
</style>
